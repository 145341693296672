import React from 'react'
import './Stylesheet.css';

const Updates = () => {
  return (
    <div className="page-container">
      <h1>Updates</h1>
      <ul className="updates-list">
        <li>Update 1: This is the first version of our webApps...</li>
        <li>Update 2: Find about the latest job updates...</li>
        <li>Update 3: We are here to give the genuine job updates...</li>
      </ul>
    </div>
  )
}

export default Updates